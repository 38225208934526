<ng-container *ngIf="calledFrom=='blog-page'; else otherPage">
    <snl-button
    [class]="'share-btn'"
    [type]="'secondary'"
    [size]="'large'"
    [icon]="'fa fa-share-nodes'"
    [disabled]="false"
    (click)="openPopup=true"
    >
    Share
    </snl-button>
</ng-container>
<ng-template #otherPage>
    <div (click)="openPopup = true" role="button">
        <em class="fa fa-share-nodes"></em>
        <span>Share</span>
    </div>
</ng-template>

<div *ngIf="openPopup">
    <snl-modal (modalClose)="openPopup = false" [type]="'medium'" [open]="openPopup">
        <div slot="heading">Share Blog</div>
        <div slot="modalContent">
        <form [formGroup]="shareBlogForm">
            <div class="input-wrapper">
                <snl-input
                    [label]="'Email:'"
                    [value]="''"
                    [type]="'email'"
                    formControlName="Email"
                    [isRequired]="true"
                ></snl-input>
                <div class="invalid-feedback d-block font-regular" *ngIf="submitted && myForm.Email.errors?.['required']">
                    Enter valid Email address
                </div>
                <div class="invalid-feedback d-block font-regular" *ngIf="submitted && myForm.Email.errors?.['email']">
                    Enter valid Email address
                </div>
            </div>
            <div class="input-wrapper">
                <snl-input
                    class="message-input"
                    [label]="'Message:'"
                    [value]="''"
                    [type]="'text'"
                    formControlName="Message"
                ></snl-input>
            </div>
        </form>
        </div>
        <div slot="modalFooter">
        <snl-button size="large" (buttonClick)="onSubmit()" [disabled]="disableSubmitButton">
            <span *ngIf="showLoader; else dontShowLoader" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <ng-template #dontShowLoader>
                <span>Share</span>
            </ng-template>
        </snl-button>
        </div>
    </snl-modal>
</div>

<div id="successToast" class="custom-toast">
    <span>Blog shared successfully.</span>
</div>
<div id="failureToast" class="custom-toast">
    <span>There is something wrong with blog sharing.</span>
</div>