export const environment = {
	name:'uat',
	production: false,
	imageUrl: "https://stbti-admin.stoltuat.com",
	baseUrl: "https://stbti-admin.stoltuat.com/api",
	hostURL: "https://stbti.stoltuat.com",
	strapiToken:"73e199b726309db6171d72793d67555d33c64713901014fba9aefed122ef2eaa0bf8725ae29fd1970c21d39c6e6f15db37e176a31adf20d60370c180b45eb57a4780340e4b670f850f0d41720be39f3a4bd9cf2ce7680eccac00132ad1cd5d2e00eda2145eada90ef54967b78f0184f5d13f234ad805bfa2b795342cc11b874b",
	port: 4201,
	googleAnalytic:'uat',
    googleAnalyticKey:'G-TWZNQLVNRZ',
    googleTagManager:'GTM-NNVSBZNF',
	clientId: "b9fef34b-9d30-4bef-a24c-981f893cc0ab",
	authority: "https://login.microsoftonline.com/a86e0b89-48f7-4a70-8e01-1962b219c224",
	redirectUri: "https://stbti.stoltuat.com",
	GRAPH_ENDPOINT: 'https://graph.microsoft.com/v1.0/me'
};