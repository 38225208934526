<div class="container2" *ngIf="(fetchedDepartmentData | keyvalue).length > 0; else loading">
    <div class="position-relative">
        <h2 class="sec-heading">Stolt Tankers Digital Products</h2>
        <div class="search">
            <div class="input-group">
                <input type="text" placeholder="Search" aria-describedby="button-addon3" class="form-control border-0 search-input" (input)="search($event)" #searchInput>
                <div class="input-group-append border-0">
                    <button id="button-addon3" type="button" class="btn btn-link text-success">
                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.75 14.7188L11.5625 10.5312C12.4688 9.4375 12.9688 8.03125 12.9688 6.5C12.9688 2.9375 10.0312 0 6.46875 0C2.875 0 0 2.9375 0 6.5C0 10.0938 2.90625 13 6.46875 13C7.96875 13 9.375 12.5 10.5 11.5938L14.6875 15.7812C14.8438 15.9375 15.0312 16 15.25 16C15.4375 16 15.625 15.9375 15.75 15.7812C16.0625 15.5 16.0625 15.0312 15.75 14.7188ZM1.5 6.5C1.5 3.75 3.71875 1.5 6.5 1.5C9.25 1.5 11.5 3.75 11.5 6.5C11.5 9.28125 9.25 11.5 6.5 11.5C3.71875 11.5 1.5 9.28125 1.5 6.5Z" fill="#788496"/></svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <ul class="nav nav-pills dept-tabs" id="pills-tab" role="tablist" *ngIf="deptListArr?.length>0">
        <li class="nav-item" role="presentation" *ngFor="let department of deptListArr; let i=index">
            <button *ngIf="department?.slug && department?.Name" class="nav-link" [ngClass]="{'active' : i==0}" [attr.id]="'pills-'+department.slug+'-tab'" data-bs-toggle="pill" (click)="selectDept(department.slug)">{{department.Name}}</button>
        </li>
    </ul>
    <div class="apps-List">
        <div class="row" *ngIf="displayDeptAppsArr?.length>0; else noProducts">
            <div class="col-lg-4 col-sm-6 app" *ngFor="let appData of displayDeptAppsArr">
                <app-each-app *ngIf="appData?.app" [appData]="appData.app"></app-each-app>
            </div>
        </div>
        <ng-template #noProducts>
            <h4 class="prod-status-text sec-heading3 text-center">No Products Found!</h4>
        </ng-template>
    </div>
    <div class="show-all-less-buttons text-center">
        <div  *ngIf="showAllLessProductsButtons">
            <snl-button *ngIf="displayDeptAppsArr?.length<=numberOfProducts; else showLessProduct"
                [class]="'show-all-button'"
                [type]="'secondary'"
                [size]="'large'"
                [disabled]="false"
                (buttonClick)="showAllProducts()"
                >
                Show all products
            </snl-button>
            <ng-template #showLessProduct>
                <snl-button
                    [class]="'show-less-button'"
                    [type]="'secondary'"
                    [size]="'large'"
                    [disabled]="false"
                    (buttonClick)="showLessProducts()"
                    >
                    Show less products
                </snl-button>
            </ng-template>
        </div>
    </div>
</div>
<ng-template #loading>
    <div class="container2 dept-data-loader">
        <div class="position-relative">
            <h2 class="sec-heading Stolt-Skeleton"></h2>
            <div class="search">
                <div class="input-group Stolt-Skeleton"></div>
            </div>
        </div>
        <ul class="nav dept-tabs">
            <li class="nav-item" *ngFor="let _ of [].constructor(4)">
                <button class="nav-link Stolt-Skeleton"></button>
            </li>
        </ul>
        <div class="apps-List">
            <div class="row">
                <div class="col-lg-4 col-sm-6 app" *ngFor="let _ of [].constructor(9)">
                    <div class="item Stolt-Skeleton"></div>
                </div>
            </div>
        </div>
    </div>
</ng-template>