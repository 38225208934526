import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceSlash'
})
export class ReplaceSlashPipe implements PipeTransform {
    transform(value: string): string {
      return value.replace('/', '<br>');
    }
}

