<div class="roadmap">
    <div class="position-relative">
        <h3 *ngIf="roadmapData?.Title && roadmapData?.id" class="roadmap-heading sec-heading3" [ngClass]="{'collapsed' : index!=0}" data-bs-toggle="collapse" [attr.href]="'#collapseExample' + roadmapData.id" role="button" aria-expanded="false" [attr.aria-controls]="'collapseExample' + roadmapData.id">{{roadmapData.Title}}</h3>
        <div class="date-arrow-wrapper position-absolute">
            <span class="date" *ngIf="roadmapData?.Date">{{roadmapData.Date | dateFormatting}}</span>
            <div class="arrow" *ngIf="roadmapData?.id" [ngClass]="{'collapsed' : index!=0}" data-bs-toggle="collapse" [attr.href]="'#collapseExample' + roadmapData.id" role="button" aria-expanded="false" aria-controls="collapseExample">
                <svg xmlns="http://www.w3.org/2000/svg" id="arrow" x="0" y="0" version="1.1" viewBox="0 0 29 29" xml:space="preserve"><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="m20.5 11.5-6 6-6-6"></path></svg>
            </div>
        </div>
    </div>
    <div class="descBox collapse" [ngClass]="{'show' : index==0}" [attr.id]="'collapseExample'+ roadmapData?.id">
        <p *ngIf="roadmapData?.Description">
            <markdown [data]="roadmapData?.Description | replaceSpace | attachImageUrl"></markdown>
        </p>
    </div>
</div>