import { Injectable, Inject, PLATFORM_ID} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { environment } from '@environments';
import * as qs from 'qs';

@Injectable()
export class MainService {
    public isBrowser: boolean = false;
    public isInnerPage: boolean = true;
    public isDemoPage: boolean = false;
    public isHeaderWhite: boolean = false;
    public baserUrl: string = environment.baseUrl;

    public disqusPublicKey: string = "";
    public disqusForum: string = "";
    public disqusDomain: string = "";
    public siteName: string = "";
    public siteHeaderLogo: string = "";
    public siteFooterLogo: string = "";
    public siteFooterContent: string = "";
    public siteFooterList: any[] = [];
    public homeTemplate: string = "";
    public blogTemplate: string = "";
    public blogSinglePost: string = "";
    public blogItemPerPage: number = 8;
    
    constructor(
        private metaTagService: Meta,
		private title: Title,
        private http: HttpClient,
        @Inject(DOCUMENT) private dom: any,
        @Inject(PLATFORM_ID) platformId: Object
    ){
        this.isBrowser = isPlatformBrowser(platformId);
    }

    // ======================= //
    // FETCH CONFIGURATION
    // ======================= //
    fetchConfiguration(){
        this.http.get(`${environment.baseUrl}/site-configuration?populate=*`).subscribe((res:any)=>{
            let data = res['data']['attributes'];
            this.disqusPublicKey = data['DisqusPublicKey'];
            this.disqusForum = data['DisqusForum'];
            this.disqusDomain = data['DisqusDomain'];
            this.siteName = data['SiteName'];
            this.siteHeaderLogo = data['SiteLogo']['data']['attributes']['url'];
            this.siteFooterLogo = data['FooterLogo']['data']['attributes']['url'];
            this.siteFooterContent = data['FooterContent'];
            this.siteFooterList = data['FooterList'];
            this.homeTemplate = data['HomePageTemplate'];
            this.blogTemplate = data['BlogPageTemplate'];
            this.blogItemPerPage = data['BlogItemPerPage'];
            this.blogSinglePost = data['BlogSinglePost'];

            if(data['favicon_16x16']['data']){
                this.generateFavIcon('16x16',this.baserUrl + data['favicon_16x16']['data']['attributes']['url'],'icon');
            }
            if(data['favicon_32x32']['data']){
                this.generateFavIcon('32x32',this.baserUrl + data['favicon_32x32']['data']['attributes']['url'],'icon');
            }
            if(data['favicon_144x144']['data']){
                this.generateFavIcon('144x144',this.baserUrl + data['favicon_144x144']['data']['attributes']['url'],'icon');
            }
            if(data['favicon_196x196']['data']){
                this.generateFavIcon('196x196',this.baserUrl + data['favicon_196x196']['data']['attributes']['url'],'icon');
                this.generateFavIcon('196x196',this.baserUrl + data['favicon_196x196']['data']['attributes']['url'],'apple-touch-icon');
            }
        });
    }

    // ======================= //
    // Update Meta Page
    // ======================= //
    updateMetaPage(SEO:any){
        this.updateMetaTitle(SEO['metaTitle']);
        this.metaTagService.updateTag({ name: 'keywords', content: SEO['keywords'] });
        this.metaTagService.updateTag({ name: 'description', content: SEO['metaDescription'] });
        this.metaTagService.updateTag({ name: 'og:title', content: SEO['metaTitle'] })
        this.metaTagService.updateTag({ name: 'og:description', content: SEO['metaDescription'] })
        this.metaTagService.updateTag({ name: 'og:image', content: this.baserUrl + SEO['metaImage']['data']['attributes']['formats']['medium']['url'] })
        this.metaTagService.updateTag({ name: 'twitter:card', content: "summary_large_image" })
        this.metaTagService.updateTag({ name: 'twitter:title', content: SEO['metaTitle'] })
        this.metaTagService.updateTag({ name: 'twitter:url', content: environment.hostURL + SEO['canonicalURL'] })
        this.metaTagService.updateTag({ name: 'twitter:image', content: this.baserUrl + SEO['metaImage']['data']['attributes']['formats']['medium']['url'] });
        this.updateCanonicalUrl(environment.hostURL + SEO['canonicalURL']);
    }

    // ======================= //
    // Update Title Page
    // ======================= //
    updateMetaTitle(title:string){
        this.title.setTitle(this.siteName + ' | ' + title);
    }

    // ======================= //
    // Generate Favicon
    // ======================= //
    generateFavIcon(sizes:string,url:string,rel:string){
        const link = this.dom.createElement('link');
        link['type'] = 'image/png';
        link['rel'] = rel;
        link['href'] = url;
        link['id'] = 'id-' + sizes;
        link.setAttribute('sizes', sizes);
        this.dom.getElementsByTagName('head')[0].appendChild(link)
    }

    // ======================= //
    // Generate ConicalUrl
    // ======================= //
    updateCanonicalUrl(url:string){
        const head = this.dom.getElementsByTagName('head')[0];
        let element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
        if (element==null) {
          element= this.dom.createElement('link') as HTMLLinkElement;
          head.appendChild(element);
        }
        element.setAttribute('rel','canonical')
        element.setAttribute('href',url)
    }

    getHeaderData(){
        const query = qs.stringify({
            populate: ['deep'],
        }, {
            encodeValuesOnly: true, 
        });
      return this.http.get(`${environment.baseUrl}/site-configuration?${query}`);
    }

    addBlogUpvote(data:Object,blogId:number){
        return this.http.post(`${environment.baseUrl}/blog/${blogId}/upvote`,data);
    }
    deleteBlogUpvote(userId:number,blogId:number){
        return this.http.post(`${environment.baseUrl}/blog/delete/${blogId}/user/${userId}`,{});
    }
   
}