import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
	public popularPost: any[] = [];
	public brands: any[] = [];
	public testimonials:any[] = [];

	constructor(
		private http: HttpClient
	) { }

    getHomePageData(){
      return this.http.get(`${environment.baseUrl}/home-page?populate=deep`);
    }

    getDepartmentsAndAppsList(){
      return this.http.get(`${environment.baseUrl}/departments?fields[0]=Name&fields[1]=slug&populate=apps[on][fields][0]=Name&populate[1]=apps.HeroSectionProduct&populate[2]=apps.HeroSectionProduct.Launch`);
    }

    getDepartmentsList(){
      return this.http.get(`${environment.baseUrl}/departments?fields[0]=Name&fields[1]=slug&fields[2]=order&populate=HeroSectionDepartment&populate[0]=HeroSectionDepartment.Image&sort[0]=Order:asc`);
    }

    getOrgStructureData(){
      return this.http.get(`${environment.baseUrl}/home-page/org-structure`);
    }
}
