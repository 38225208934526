<div class="hTreeWrapper">
    <div class="horizontalLine" [class]="'horizontalLine-'+(data.level+1)+indexNumber+data?.id+randomNumber"></div>
    <ul class="hTree" [class]="'hTree-'+(data.level+1)+indexNumber+data?.id+randomNumber" *ngIf="data?.children?.length>0">
        <li *ngFor="let employee of data.children; let i=index; let last = last" [ngClass]="!employee.vertical ? 'hLi' : 'vLi'">
            <div class="app-each-user">
                <app-each-user [empData]="{name:employee?.name, description:employee?.description, flags:employee?.flags, classes:'emp-box horizontalBox', elId:employee?.elId, Active:employee?.Active}"></app-each-user>
            </div>
            <ng-container *ngIf="!employee.vertical; else vertical">
                <app-tree-part-horizontal *ngIf="employee?.children?.length>0" [data]="employee" [indexNumber]="i"></app-tree-part-horizontal>
            </ng-container>
            <ng-template #vertical>
                <app-tree-part-vertical *ngIf="employee?.children?.length>0" [data]="employee" [indexNumber]="i"></app-tree-part-vertical>
            </ng-template>
        </li>
    </ul>
</div>