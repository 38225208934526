import { Component,Input, ElementRef, Renderer2 } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BlogService, AuthService } from '@services';
import { Subscription, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '@environments';

@Component({
  selector: 'app-share-popup',
  templateUrl: './share-popup.component.html'
})
export class SharePopupComponent {
  
  public openPopup:boolean=false;
	public hostURL: string = environment.hostURL;
  public submitted:boolean= false;
  public disableSubmitButton:boolean=false;
  public showLoader:boolean=false;
  
	//subscriptions
  private userDataSubscription!: Subscription;
  private shareBlogSubscription!: Subscription;

  public shareBlogForm = new FormGroup({
    Name: new FormControl('', Validators.required),
    Message: new FormControl(''),
    Email: new FormControl('', [Validators.required, Validators.email]),
    Url: new FormControl('', Validators.required),
  });

  @Input() calledFrom: any;
  @Input() blogUrl: any;
  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private blogService: BlogService,
    private authService: AuthService,
    private router: Router
  ) {}
  

  ngOnInit(): void {
    this.userDataSubscription=this.authService.getUserData().subscribe((userData:any)=>{
      this.shareBlogForm.patchValue({
        Name:userData.username,
        Url:this.calledFrom=='other-page' ? this.blogUrl : this.hostURL+this.router.url
      })
    })
  }

  // Getter method to access formcontrols
  get myForm() {
    return this.shareBlogForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (!this.shareBlogForm.valid) {
      return;
    } else {
      this.disableSubmitButton=true; //disable submit button
      this.showLoader=true;//show loader
      this.shareBlogSubscription=this.blogService.shareBlog(this.shareBlogForm.value)
      .pipe(
        catchError(error => {
          // Handle the error here
          this.submitted = false;
          this.openPopup=false;
          this.disableSubmitButton=false; //enable submit button
          this.showLoader=false;//hide loader
          
          let failureToast = this.el.nativeElement.querySelector('#failureToast');//get editor element
          this.renderer.setAttribute(failureToast, 'class', 'show');//set class to editor element
          setTimeout(() =>{
            this.renderer.setAttribute(failureToast, 'class', '');
          }, 3000);
          return throwError(() => error);
        })
      ).subscribe(() => {
        this.shareBlogForm.patchValue({
          Email:'',
          Message:''
        })
        this.submitted = false;
        this.openPopup=false;
        this.disableSubmitButton=false; //enable submit button
        this.showLoader=false;//hide loader
        
        let successToast = this.el.nativeElement.querySelector('#successToast');//get editor element
        this.renderer.setAttribute(successToast, 'class', 'show');//set class to editor element
        setTimeout(() =>{
          this.renderer.setAttribute(successToast, 'class', '');
        }, 3000);
      })
    }
  }

	ngOnDestroy() {
	  if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
	  }
	  if (this.shareBlogSubscription) {
      this.shareBlogSubscription.unsubscribe();
	  }
	}

}
