import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@environments';

@Pipe({
  name: 'attachImageUrl'
})
export class AttachImageUrlPipe implements PipeTransform {
  transform(value: string): string {
    let dataAfterUrlAttached = value.replace(/\(\/uploads\//g, '('+environment.imageUrl+'/uploads/');
    return dataAfterUrlAttached;
  }
}
