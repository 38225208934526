import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html'
})
export class RightSidebarComponent implements AfterViewInit {
  @Input() resourceData: any;
  @ViewChild('myIdentifier')
  myIdentifier!: ElementRef;

  ngAfterViewInit() {
    const width = this.myIdentifier.nativeElement.offsetWidth;
    const height = this.myIdentifier.nativeElement.offsetHeight;
  }

  linkClick(url:string){
    // Check if the URL starts with "http://" or "https://"
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      // If it doesn't start with either, add "http://" to the URL
      url = 'http://' + url;
    }
    // Open the URL in a new tab
    window.open(url, '_blank');
  }
}
