import { Component, Input } from '@angular/core';
import { environment } from '@environments';

@Component({
  selector: 'app-pol-pro',
  templateUrl: './pol-pro.component.html'
})
export class PolProComponent {
  @Input() blogData: any;
	public baseUrl: string = environment.baseUrl;
	public imageUrl: string = environment.imageUrl;

  linkClick(url:string){
    // Check if the URL starts with "http://" or "https://"
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      // If it doesn't start with either, add "http://" to the URL
      url = 'http://' + url;
    }
    // Open the URL in a new tab
    window.open(url, '_blank');
  }
}
