import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@environments';
import { Router } from '@angular/router';
import { MainService, AuthService } from '@services';
import { Subscription, catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html'
})
export class ArticleComponent implements OnInit {
	public baseUrl: string = environment.baseUrl;
	public hostURL: string = environment.hostURL;
	public imageUrl: string = environment.imageUrl;
  public numberOfComments!:any;
  public numberOfUpvotes!:any;
  public isUpvoteTrue:boolean=false;
  
  //subscriptions
	private userDataSubscription!: Subscription;
	private addBlogUpvoteSubscription!: Subscription;
	private deleteBlogUpvoteSubscription!: Subscription;
  
  @Input() articleData: any;

  constructor(
    private router: Router,
    private mainService: MainService,
    private authService: AuthService
    ){}


  ngOnInit(): void {
    this.numberOfComments=this.articleData?.comments?.length;
    this.numberOfUpvotes=this.articleData?.blog_upvotes?.length;

    this.articleData.Content = this.removeImg(this.articleData?.Content)

    this.userDataSubscription=this.authService.getUserData().subscribe((userData:any)=>{
      if(this.articleData?.blog_upvotes?.length>0)
      {
        this.isUpvoteTrue = this.articleData?.blog_upvotes?.some((upvote: any) => 
        {
          return upvote?.user?.id === userData?.id
        });
      }
    })
  }

  removeImg(html: string): string {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    const images = tempDiv.querySelectorAll('img');
    images.forEach(imgElement => {
      imgElement.remove();
    });
    
    const para = tempDiv.querySelectorAll('p');
    para.forEach(pElement => {
      if (pElement.innerHTML.trim() === '') {
        pElement.remove();
      }
    });
    return tempDiv.innerHTML;
  }

  navigateTo(path:string){
    this.router.navigateByUrl(path);
    sessionStorage.setItem('previousPath',this.router.url)
  }

  onUpvoteClick(commentId:any) {
    if(this.isUpvoteTrue)
    {
      this.deleteBlogUpvote(commentId);
    }
    else{
      this.addBlogUpvote(commentId);
    }
  }
  addBlogUpvote(blogId:any){
    let data={
      vote:true,
      user:this.authService.UserObj.id
    }
    this.isUpvoteTrue=true;
    this.numberOfUpvotes++;
    this.addBlogUpvoteSubscription=this.mainService.addBlogUpvote(data,blogId)
		.pipe(
			catchError(error => {
			  // Handle the error here
			  console.error('An error occurred:', error);
        this.isUpvoteTrue=false;
        this.numberOfUpvotes--;
			  return throwError(() => error);
			})
		).subscribe(()=>{
    })
  }
  deleteBlogUpvote(blogId:any){
    this.isUpvoteTrue=false;
    this.numberOfUpvotes--;
    this.deleteBlogUpvoteSubscription=this.mainService.deleteBlogUpvote(this.authService.UserObj.id,blogId)
		.pipe(
			catchError(error => {
			  // Handle the error here
			  console.error('An error occurred:', error);
        this.isUpvoteTrue=true;
        this.numberOfUpvotes++;
			  return throwError(() => error);
			})
		).subscribe(()=>{
    })
  }
  
  ngOnDestroy() {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
    if (this.addBlogUpvoteSubscription) {
      this.addBlogUpvoteSubscription.unsubscribe();
    }
    if (this.deleteBlogUpvoteSubscription) {
      this.deleteBlogUpvoteSubscription.unsubscribe(); // Check if defined before unsubscribing
    }
  }
}
