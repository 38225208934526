import { Component, ElementRef, Renderer2, Input, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from '@environments';

@Component({
  selector: 'app-team-structure',
  templateUrl: './team-structure.component.html'
})
export class TeamStructureComponent implements OnChanges {
	constructor(
		private renderer: Renderer2,
		private el: ElementRef
	) {}

	public baseUrl: string = environment.baseUrl;
	public tree:any={};
	public level:number=0;
  	@Input() teamData: any;
	
	private isDragging = false;
	private startX!: number;
	private scrollLeft!: number;

	onMousedown(event: MouseEvent) {
		event.preventDefault();
		this.isDragging = true;
		this.startX = event.pageX;
		this.scrollLeft = this.el.nativeElement.querySelector('.tree-wrapper-main').scrollLeft;
		document.body.style.cursor = 'grabbing';
	}
	onMousemove(event: MouseEvent) {
		if (!this.isDragging) {
		  return;
		}
		const deltaX = event.pageX - this.startX;
		this.el.nativeElement.querySelector('.tree-wrapper-main').scrollLeft = this.scrollLeft - deltaX;
	}
	onMouseup() {
		this.isDragging = false;
		document.body.style.cursor = 'auto';
	}
	onMouseleave() {
	  if (this.isDragging) {
		this.isDragging = false;
		document.body.style.cursor = 'auto';
	  }
	}
	
	ngOnChanges(changes: SimpleChanges): void {
		if(changes.hasOwnProperty('teamData')) {
			if(Object.keys(changes?.['teamData'].currentValue).length > 0){
				this.tree=this.makeTree(changes?.['teamData'].currentValue);
				
				setTimeout(() => {
					this.setHorizontalLine();//set tree horizontal line width and margin
					this.setVerticalLines();//set vertical lines height
					this.scrollOrgStructure();
				});
			}
		}
	}

	scrollOrgStructure(){
		const widthTreeWrapper = this.el.nativeElement.querySelector('.tree-wrapper').offsetWidth;
		const widthTreeSection = this.el.nativeElement.querySelector('.tree-wrapper-main').offsetWidth;
		const scrollAmount = (widthTreeWrapper-widthTreeSection)/2;
		this.el.nativeElement.querySelector('.tree-wrapper-main').scrollLeft=scrollAmount;
	}

	makeTree(organisationData:any){
		let obj:any={
			id:organisationData?.id,
			name:organisationData?.name,
			description:organisationData?.profileDescription,
			flags:organisationData?.Country,
			level:0,
			vertical:false,
			Active:organisationData?.Active,
			// url:organisationData?.departments?.data[0]?.attributes?.slug,
			children:[]
		}
		if(organisationData?.employees?.length>0){
			let abc=this.insertChild(organisationData?.employees,1);
			obj={...obj, ...abc};
		}
		return  obj;
	}

	insertChild(employeesArray:any,level:number){
		this.level--;
		let count=0;
		let newArr:any=[];
		newArr['children']=employeesArray.map((eachEmployee:any,index:number)=>{
			let obj:any={
				id:eachEmployee?.id,
				name:eachEmployee?.name,
				description:eachEmployee?.profileDescription,
				flags:eachEmployee?.Country,
				level:level,
				Active:eachEmployee?.Active,
				children:[]
			}
			// if(eachEmployee?.attributes?.departments?.data?.length>0){
			// 	if(eachEmployee?.attributes?.departments?.data[0]?.attributes?.slug){
			// 		obj['url']=eachEmployee.attributes.departments.data[0].attributes.slug;
			// 		obj['department']=eachEmployee.attributes.departments.data[0].attributes;
			// 	}
			// }

			if (index === employeesArray?.length - 1) {
				obj['elId']='box-l';
			}else if (index === 0) {
				obj['elId']='box-f';
			}

			if(eachEmployee?.employees){
				if(eachEmployee?.employees?.length>0){
					count++;
					let abc=this.insertChild(eachEmployee.employees,level+1);
					obj={...obj, ...abc};
				}
			}
			return obj;
		})
		if(count>0){
			newArr['vertical']=false;
		}
		else{
			newArr['vertical']=true;
		}
		
		return newArr;
	}

	setHorizontalLine(){
		// Select all elements with the class 'abc'
		const horizontalLineElements = this.el.nativeElement.querySelectorAll('.horizontalLine');

		// Iterate through each element
		horizontalLineElements.forEach((element:any) => {
			// Get the class list of the current element
			const classList = element.classList;

			// Find the second class in the list (assuming there are at least two classes)
			if (classList.length > 1) {
				const secondClass = classList[1];

				// Find the position of the first '-'
				const dashIndex = secondClass.indexOf('-');

				// Extract the substring after the first '-'
				if (dashIndex !== -1) {
					const stringAfterDash = secondClass.substring(dashIndex + 1);

					const treeElement=this.el.nativeElement.querySelector(`.hTree-${stringAfterDash}`);
					const widthTree=treeElement.getBoundingClientRect().width;

					//fetch margin of line from left
					const firstHorBoxElement=this.el.nativeElement.querySelector(`.hTree-${stringAfterDash}>li:first-child>.app-each-user>app-each-user>.horizontalBox#box-f`);
					const firstLiElement = this.el.nativeElement.querySelector(`.hTree-${stringAfterDash}>li:first-child`);
					const firstLiClassList = firstLiElement.classList;
					let marginLeftHline:number;
					if(firstLiClassList.contains('hLi')){
						marginLeftHline=firstLiElement?.getBoundingClientRect().width/2 ?? 0;
					}
					else{
						marginLeftHline=firstHorBoxElement?.getBoundingClientRect().width/2 ?? 0;
					}
					
					//fetch margin of line from right
					const lastHorBoxElement=this.el.nativeElement.querySelector(`.hTree-${stringAfterDash}>li:last-child>.app-each-user>app-each-user>.horizontalBox#box-l`);
					const lastLiElement=this.el.nativeElement.querySelector(`.hTree-${stringAfterDash}>li:last-child`);
					const lastLiClassList = lastLiElement.classList;
					const widthTreeLastLi= lastLiElement?.getBoundingClientRect().width ?? 0;
					let marginRightHline:number;
					if(lastLiClassList.contains('hLi')){
						marginRightHline=widthTreeLastLi/2;
					}
					else{
						marginRightHline=(2*widthTreeLastLi - lastHorBoxElement?.getBoundingClientRect().width)/2 ?? 0;
					}

					const lineWidth = widthTree-((marginLeftHline + marginRightHline))

					const horizontalLine=this.el.nativeElement.querySelector(`.horizontalLine-${stringAfterDash}`);
					this.renderer.setStyle(horizontalLine, 'width', lineWidth+'px');
					this.renderer.setStyle(horizontalLine, 'margin-left', marginLeftHline+'px');

					//Slight change in css of tree and line
					const hTreeElement = this.el.nativeElement.querySelectorAll(`.hTree-${stringAfterDash}>li`);
					if(hTreeElement?.length === 1){
						this.renderer.setStyle(treeElement, 'margin-top', 18+'px');
						this.renderer.setStyle(horizontalLine, 'display', 'none');
					}
				}
			}
		});

	}

	setVerticalLines(){
		// Select all elements with the class 'abc'
		const horizontalLineElements = document.querySelectorAll('.verticalLine');

		// Iterate through each element
		horizontalLineElements.forEach(element => {
			// Get the class list of the current element
			const classList = element.classList;

			// Find the second class in the list (assuming there are at least two classes)
			if (classList.length > 1) {
				const secondClass = classList[1];

				// Find the position of the first '-'
				const dashIndex = secondClass.indexOf('-');

				// Extract the substring after the first '-'
				if (dashIndex !== -1) {
					const stringAfterDash = secondClass.substring(dashIndex + 1);

					//akshay
					let heightSecondTree=this.el.nativeElement.querySelector('.vTree-'+stringAfterDash).getBoundingClientRect().height;//height of tree
					let heightLastVerBox=this.el.nativeElement.querySelector('.vTree-'+stringAfterDash+' .verticalBox#box-l').getBoundingClientRect().height;//height of last horizontal box
					const lineHeight=heightSecondTree+20-(heightLastVerBox/2);//height of line
					let verticalLine=this.el.nativeElement.querySelector('.verticalLine-'+stringAfterDash);//get element horizontal line
					this.renderer.setStyle(verticalLine, 'height', lineHeight+'px');
					//
				}
			}
		});
	}
}
