<div class="tree-section" 
    (mousedown)="onMousedown($event)" 
    (mousemove)="onMousemove($event)" 
    (mouseup)="onMouseup()"
    (mouseleave)="onMouseleave()">
    <div class="tree-wrapper-main h-scrollbar custom-scrollbar">
        <div class="tree-wrapper">
            <app-each-user [empData]="{name:tree?.name, description:tree?.description, flags:tree?.flags, classes:'top-div', elId:'', Active:tree?.Active}"></app-each-user>
            <ng-container *ngIf="!tree.vertical; else vertical">
                <app-tree-part-horizontal [data]="tree"></app-tree-part-horizontal>
            </ng-container>
            <ng-template #vertical>
                <app-tree-part-vertical [data]="tree"></app-tree-part-vertical>
            </ng-template>
        </div>
    </div>
</div>