<div class="each-app">
    <div class="app-wrapper">
        <div class="app-content">
            <a *ngIf="appData?.Name" class="text-decoration-none" (click)="linkClick(appData.buttons.URL)" role="button"><h4 class="sec-heading3">{{appData.Name}}</h4></a>
            <p *ngIf="appData?.Description">
                <markdown [data]="appData?.Description | replaceSpace | removeImageText"></markdown>
            </p>
            <a *ngIf="appData?.slug" class="learn-more" [routerLink]="'/product/'+appData.slug">Learn More</a>
        </div>
    </div>
</div>