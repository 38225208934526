import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, filter, switchMap, take } from 'rxjs';
import { environment } from '@environments';
import { AuthService } from '@services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private baseUrl=environment.baseUrl;
  private hostURL=environment.hostURL;
  private envName=environment.name;
  private strapiToken=environment.strapiToken;
  constructor(
    private authService:AuthService
    ) {} 
    
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // add auth header with jwt if user is logged in and request is to the api url
    const isAuthUrl =
      request.url === `${this.baseUrl}/login` ||
      request.url === `${this.baseUrl}/signup` ||
      request.url === `https://graph.microsoft.com/v1.0/me`;

    if (!isAuthUrl) {
      const currentUser = this.authService.UserObj;
      const accessToken = this.authService.token;
      const isLoggedIn = currentUser && accessToken;
      const isApiUrl = request.url.startsWith(this.baseUrl);
      if (isLoggedIn && isApiUrl) {
        request = this.addBearerToken(request, accessToken);
      } else {
        // If the token is not available, wait for it to become available
        return this.authService.getTokenObservable().pipe(
          filter((token) => !!token), // Wait until a non-null token is emitted
          take(1), // Take the first emitted value (i.e., the token)
          switchMap((token) => {
            // Add the token to the request headers and handle the API call
            request = this.addBearerToken(request, token);
            return next.handle(request);
          })
        );
      }
    }
    else if(request.url===this.baseUrl+"/login" || request.url===this.baseUrl+"/signup"){
      request = this.addBearerToken(request, this.strapiToken);
    }

    return next.handle(request);
  }

  private addBearerToken(
    request: HttpRequest<unknown>,
    token: string | null
  ): HttpRequest<unknown> {
    if (token) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return request;
  }
}