import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ButtonModule,
  HeaderStandaloneModule,
  ModalModule,
  InputModule,
} from 'snl-complib';


const snlComplibModule: any[] = [
  HeaderStandaloneModule,
  ButtonModule,
  ModalModule,
  InputModule
  ];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ...snlComplibModule
    
  ],
  exports: [
    ...snlComplibModule
  ]
})
export class SharedSnlComplibModule { }
