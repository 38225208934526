import { Component, Input, OnInit, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-apps-section',
  templateUrl: './apps-section.component.html'
})
export class AppsSectionComponent implements OnChanges {
  @Input() fetchedDepartmentData: any;
  @ViewChild('searchInput', { static: false }) searchInput!: ElementRef<HTMLInputElement>;

  constructor(
    private el: ElementRef
    ){}

  public deptListArr: any = [
    {
      Name: "All Products",
      slug: "all-products"
    }
  ];
  public deptAppsArr: any[] = [];
  public displayDeptAppsArr: any = [];
  public selectedDept: string = 'all-products';
  public searchedText: string = '';
  public showAllLessProductsButtons: boolean = true;
  public numberOfProducts: number = 9;
  public productsToShow: number = this.numberOfProducts;

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.hasOwnProperty('fetchedDepartmentData')) {
      this.initializeData();
    }
  }

  initializeData() {
    this.generateDeptArr();
    this.sortArray();
    this.updateDisplayedApps();
  }

  generateDeptArr() {
    if (!this.fetchedDepartmentData || Object.keys(this.fetchedDepartmentData).length === 0) {
      return;
    }
    this.fetchedDepartmentData.forEach((dept: any) => {
      this.processDepartment(dept);
    });
  }

  sortArray() {
    this.deptAppsArr.sort((a: any, b: any) => {
      const nameA = a.app.Name.toLowerCase();
      const nameB = b.app.Name.toLowerCase();

      return nameA.localeCompare(nameB);
    });
  }

  processDepartment(dept: any) {
    if (!(dept?.attributes?.apps?.data?.length)) {
      return;
    }
    const { Name, slug } = dept?.attributes;
    this.deptListArr.push({ Name, slug });

    dept.attributes.apps.data.forEach((app: any) => {
      this.processApp(app, Name, slug);
    });
  }

  processApp(app: any, deptName: string, deptSlug: string) {
    const { id, attributes } = app;
    const { Name, HeroSectionProduct } = attributes;
    const { Description, Launch } = HeroSectionProduct;

    const buttons = Launch ? { URL: Launch.URL || '', Label: Launch.Label || '' } : { URL: '', Label: '' };

    const existingApp = this.deptAppsArr.find((item: any) => item.app.id === id);

    if (existingApp) {
      existingApp.dept.push({ deptName, deptSlug }); //It basically update the dept of existing app in deptAppsArr array. Because existingApp referes to to the same reference in memory.
    } else {
      this.deptAppsArr.push({
        dept: [{ deptName, deptSlug }],
        app: { id, Name, Description, slug: attributes.slug, buttons }
      });
    }
  }

  selectDept(deptSlug: string) {
    this.searchedText = "";
    this.selectedDept = deptSlug;
    this.updateDisplayedApps();
    if (this.searchInput) {
      this.searchInput.nativeElement.value = '';
    }
  }

  updateDisplayedApps() {
    this.displayDeptAppsArr = this.deptAppsArr.filter(app =>
      this.selectDeptLogic(app, this.selectedDept) && this.searchLogic(app, this.searchedText)
    );
    this.showAllLessProductsButtons = this.displayDeptAppsArr.length > this.numberOfProducts;
    this.displayDeptAppsArr = this.displayDeptAppsArr.slice(0, this.productsToShow);
  }

  selectDeptLogic(app: any, deptSlug: string) {
    return app.dept.some((deptData: { deptSlug: string }) => deptData.deptSlug === deptSlug || deptSlug === 'all-products');
  }

  search(event: any) {
    this.searchedText = event?.target?.value;
    this.updateDisplayedApps();
  }

  searchLogic(app: any, searchKeyword: string) {
    if (searchKeyword) {
      const nameIncludesKeyword = app.app.Name && app.app.Name.toLowerCase().includes(searchKeyword.toLowerCase());
      const descriptionIncludesKeyword = app.app.Description && app.app.Description.toLowerCase().includes(searchKeyword.toLowerCase());
  
      return nameIncludesKeyword || descriptionIncludesKeyword;
    }
    return true; // Show the app if no search keyword provided
  }
  

  showAllProducts() {
    this.toggleProductsVisibility(true);
  }

  showLessProducts() {
    this.toggleProductsVisibility(false);
    setTimeout(()=>{
      this.scrollToComments();
    })
  }

  toggleProductsVisibility(showAll: boolean) {
    this.productsToShow = showAll ? this.deptAppsArr.length : this.numberOfProducts;
    this.updateDisplayedApps();
  }
  
  private scrollToComments() {
    let showAllLessButton: HTMLElement;
    showAllLessButton = this.el.nativeElement.querySelector(
      ".show-all-less-buttons"
    );
    window.scroll({
      top: this.getTopOffset(showAllLessButton),
      left: 0,
      behavior: "smooth"
    });
  }
  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 350;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }


}
