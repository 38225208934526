<div class="documentation" *ngIf="resourceData?.length>0">
    <h3 class="sec-heading3">Documentation</h3>
    <div class="resource-list" *ngFor="let resource of resourceData">
        <h4 *ngIf="resource?.Name">{{resource.Name}}</h4>
        <ul *ngIf="resource?.Links?.length>0">
            <li *ngFor="let list of resource.Links" #myIdentifier>
                <a *ngIf="list?.Label && list?.URL" (click)="linkClick(list?.URL)" role="button">{{list.Label}}</a>
            </li>
        </ul>
    </div>
</div>