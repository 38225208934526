<div class="peoples-wrapper">
    <h4 class="title" *ngIf="peopleData?.Designation">{{peopleData.Designation}}</h4>
    <div class="people-list" *ngIf="peopleData?.employees?.length>0">
        <a [routerLink]="emp?.departments[0]?.slug ? '/department/'+emp?.departments[0]?.slug : '#'" class="each-people" *ngFor="let emp of peopleData.employees">
            <div class="img-wrapper">
                <img *ngIf="emp?.profileImage?.formats?.thumbnail?.url; else defaultImage" [src]="imageUrl+emp.profileImage.formats.thumbnail.url" alt="Employee Image">
                <ng-template #defaultImage>
                    <img src="../../../assets/images/default.png" alt="Employee Image">
                </ng-template>
            </div>
            <div class="details">
                <span class="name" *ngIf="emp?.name">{{emp.name}}</span>
                <p class="profile" *ngIf="emp?.profileDescription">{{emp.profileDescription}}</p>
                <p class="profileDept" *ngIf="emp?.Profile">{{emp.Profile}}</p>
            </div>
        </a>
    </div>    
</div>