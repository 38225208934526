import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

	constructor(
		private http: HttpClient
	) { }
 
	 // ======================= //
    // Fetch Dept Page
    // ======================= //
    getDeptPageData(slug:string){
      return this.http.get(`${environment.baseUrl}/department/${slug}?populate=deep`);
    }

}
