import { Component, ElementRef, OnInit, Renderer2, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BlogService, AuthService, StateService } from '@services';

import 'quill-emoji/dist/quill-emoji.js';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';
import { Subscription, catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-comment-box',
  templateUrl: './comment-box.component.html'
})
export class CommentBoxComponent implements OnInit {

	modules = {};
  blured = false;
  focused = false;
  public submitted = false;
  public disableSubmitButton:boolean=false;
  public showLoader:boolean=false;
  public commentForm = new FormGroup({
    UserName: new FormControl('', Validators.required),
    Content: new FormControl('', Validators.required),
    user: new FormControl('', Validators.required),
    IdParent: new FormControl(''),
  });
  @Input() parentCommentData: any;
  
  //subscriptions
	private userDataSubscription!: Subscription;
	private addCommentSubscription!: Subscription;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private blogService: BlogService,
    private authService: AuthService,
    private stateService: StateService
  ) {
		this.modules = {
			'emoji-shortname': true,
			'emoji-textarea': true,
			'emoji-toolbar': true,
			'toolbar': {
				container: [
					['bold', 'italic'],
					['emoji'],
				],
				handlers: { 'emoji': function () { } }
			}
		}
  }

  ngOnInit(): void {
    this.userDataSubscription=this.authService.getUserData().subscribe((userData:any)=>{
      this.commentForm.patchValue({
        UserName:userData?.username,
        user:userData?.id,
        IdParent:this.parentCommentData?.commentId
      })
    })
  }

  // Getter method to access formcontrols
  get myForm() {
    return this.commentForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (!this.commentForm.valid) {
      return;
    } else {
      this.disableSubmitButton=true; //disable submit button
      this.showLoader=true;//show loader
      this.addCommentSubscription=this.blogService.addComment(this.commentForm.value, this.stateService?.currentBlogId)
      .pipe(
        catchError(error => {
          // Handle the error here
          this.submitted = false;
          this.disableSubmitButton=false; //enable submit button
          this.showLoader=false;//hide loader
          return throwError(() => error);
        })
      ).subscribe((data: any) => {
        this.stateService.updateInvokeGetBlogDetails(true);// load blog data again so that comments can get refreshed
        this.commentForm.patchValue({
          Content:''
        })
        this.submitted = false;
        this.disableSubmitButton=false; //enable submit button
        this.showLoader=false;//hide loader
        // this.closeReplyTo();//remove reply content
      })
    }
  }

  
  //Quill Editor functions
  changedEditor(event: EditorChangeContent | EditorChangeSelection | any) {
    if(event?.text?.length>0){
      this.commentBoxClick('filled')
    }
  }
  focus($event: any) {
    this.focused = true
    this.blured = false
    this.commentBoxClick('focused')
  }
  blur($event: any) {
    this.focused = false
    this.blured = true
    this.commentBoxClick()
  }
  commentBoxClick(commentBoxState:string='') {
    let editor = this.el.nativeElement.querySelector('.editor');//get editor element
    let classToAdd=this.myForm.Content.value ? 'editor position-relative filled' : 'editor position-relative';
    this.renderer.setAttribute(editor, 'class', commentBoxState ? 'editor position-relative '+commentBoxState : classToAdd);//set class to editor element
  }

  ngOnDestroy() {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
    if (this.addCommentSubscription) {
      this.addCommentSubscription.unsubscribe();
    }
  }

}
