import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // Token has expired, refresh it
          
          let loginData={
            identifier: this.authService.ssoUserDetails.email,
            password: this.authService.ssoUserDetails.password
          }
          if(!this.authService.isRefreshed){
            this.authService.isRefreshed=true;
            this.authService.setTokenObservable('');
            return this.authService.loginStrapi(loginData).pipe(
              switchMap(({jwt, user}:any) => {

                if(jwt){
                  this.authService.setTokenObservable(jwt);
                }
                if(Object.keys(user)?.length > 0){
                  this.authService.setUserData(user);
                }

                // Retry the original request with the new token
                const authRequest = this.addBearerToken(request, jwt);
                return next.handle(authRequest);
              }),
              finalize(() => {
                this.authService.isRefreshed = false;
              })
            );
          }
          else{
            return this.authService.getTokenObservable().pipe(
              filter((token) => !!token), // Wait until a non-null token is emitted
              take(1), // Take the first emitted value (i.e., the token)
              switchMap((token) => {
                // Add the token to the request headers and handle the API call
                request = this.addBearerToken(request, token);
                return next.handle(request);
              })
            );
          }
        }
			  return throwError(() => error);
      })
    );
  }

  private addBearerToken(
    request: HttpRequest<unknown>,
    token: string | null
  ): HttpRequest<unknown> {
    if (token) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return request;
  }
}
