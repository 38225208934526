import { Component, Input } from '@angular/core';
import { environment } from '@environments';

@Component({
  selector: 'app-each-user',
  templateUrl: './each-user.component.html'
})
export class EachUserComponent {
	public baseUrl: string = environment.baseUrl;
  @Input() empData: any;
}
