import { Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { environment } from '@environments';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent {
  constructor(
  private msalService: MsalService) {
    this.msalService.logoutRedirect({
      postLogoutRedirectUri: environment.redirectUri
    });
  }
}
