<!-- Start Header -->
<header class="fixed-top">
    <nav>
        <snl-header-standalone *ngIf="data.attributes; else loading">
            <a routerLink="/" logo>
                <img [src]="imageUrl + data.attributes.SiteLogoDesktop.data.attributes.url" class="logo" [attr.alt]="'dummy text'" />
            </a>
            <div title class="siteTitle">{{data.attributes.SiteName}}</div>
            <div right-section class="loggedInUser">
                <span class="username">{{this.authService.UserObj.username}}</span>
                <span class="username-mobile">{{shortUserName}}</span>
                <div class="img-wrapper">
                    <img src="../../../assets/images/default.png" alt="alternative user image">
                </div>
            </div>
        </snl-header-standalone>
        <ng-template  #loading>
            <div class="header-loading">
                <div class="logo Stolt-Skeleton"></div>
                <div class="header-vertical-line"></div>
                <div class="header-text">
                    <div class="title Stolt-Skeleton"></div>
                    <div class="loggedInUser">
                        <span class="username Stolt-Skeleton"></span>
                        <div class="img-wrapper"><img src="../../../assets/images/default.png" alt="alternative user image"></div>
                    </div>
                </div>
            </div>
        </ng-template >
    </nav>
</header>
<!-- End Header --> 