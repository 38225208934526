import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-email-redirection',
  templateUrl: './email-redirection.component.html'
})
export class EmailRedirectionComponent {
  private sub: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute
    ) {}
    
  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.router.navigateByUrl('/'+params['pageName']+'/'+params['pageSlug']);
    });
  }
}