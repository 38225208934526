import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceSpace'
})
export class ReplaceSpacePipe implements PipeTransform {
  transform(value: string): string {
    let dataAfterSpaceReplaced=value.replace(/\n/g, '<br>');
    return dataAfterSpaceReplaced;
  }
}

