<form [formGroup]="commentForm">
    <div class="editor position-relative">
        <div *ngIf="parentCommentData?.replyTo" class="reply-to">
            <div class="reply-content">Reply To: <span>{{parentCommentData.replyTo}}</span>
            </div>
        </div>
        <quill-editor
            placeholder="write a comment"
            formControlName="Content"
            (onFocus)="focus($event)" 
            (onEditorChanged)="changedEditor($event)" 
            (onBlur)="blur($event)"
            [modules]="modules">
        </quill-editor>
        
        <div role="button" class="post-comment">
            <snl-button
            [type]="'primary'"
            [size]="'small'"
            [disabled]="false"
            [icon]="''"
            [iconOnly]="false"
            (buttonClick)="onSubmit()"
            [disabled]="disableSubmitButton"
            >
                <span *ngIf="showLoader; else dontShowLoader" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <ng-template #dontShowLoader>
                    <span>Comment</span>
                </ng-template>
            </snl-button>
        </div>
        <div class="invalid-feedback d-block" *ngIf="submitted && myForm.Content.errors?.['required']">
            Enter your Comment
        </div>
    </div>
</form>