import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser'; 

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MainService, AuthInterceptor } from '@services';
import { HeaderComponent } from '@components';
import { EmailRedirectionComponent } from '@components';
import { SharedSnlComplibModule } from './shared-snl-module/shared-snl-complib-module';
import { ErrorInterceptor } from './services/error.interceptor';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalModule, MsalService, MsalRedirectComponent } from '@azure/msal-angular';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { environment } from '@environments';
const isIE =
window.navigator.userAgent.indexOf("MSIE ") > -1 ||
window.navigator.userAgent.indexOf("Trident/") > -1;

@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		EmailRedirectionComponent
	],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }), 
		AppRoutingModule,
		HttpClientModule,
		SharedSnlComplibModule,
		MsalModule.forRoot(
			new PublicClientApplication({
			  auth: {
				clientId: environment.clientId,
				authority:
				  environment.authority,
				redirectUri: environment.redirectUri,
			  },
			  cache: {
				cacheLocation: "localStorage",
				storeAuthStateInCookie: isIE,
			  },
			}),
			{
			  interactionType: InteractionType.Redirect,
			  authRequest: {
				scopes: ["user.read"],
			  },
			},
			{
			  interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
			  protectedResourceMap: new Map([
				["https://graph.microsoft.com/v1.0/me", ["user.read"]],
			  ]),
			}
		  ),
	],
	providers: [
		MainService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorInterceptor,
			multi: true
		},
		{
		  provide: HTTP_INTERCEPTORS,
		  useClass: MsalInterceptor,
		  multi: true,
		},
		MsalGuard,
	],
	bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }