import { NgModule } from '@angular/core';
import { InitialNavigation, RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { LogoutComponent } from './components/logout/logout.component';

const routes: Routes = [
	{
		path: '',
		canActivate:[MsalGuard],
		loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
	},
	{
		path: 'product/:slug',
		canActivate:[MsalGuard],
		loadChildren: () => import('./pages/product/product.module').then(m => m.ProductModule)
	},
	{
		path: 'department/:slug',
		canActivate:[MsalGuard],
		loadChildren: () => import('./pages/department/department.module').then(m => m.DepartmentModule)
	},
	{
		path: 'blog/:slug',
		canActivate:[MsalGuard],
		loadChildren: () => import('./pages/blog/blog.module').then(m => m.BlogModule)
	},
	{
		path: 'logout',
		component: LogoutComponent
	},
	{
		path: '**',
		redirectTo: '',
		pathMatch: 'full'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', initialNavigation:'enabled' as InitialNavigation})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
