import { Component, Input } from '@angular/core';
import { environment } from '@environments';

@Component({
  selector: 'app-each-app',
  templateUrl: './each-app.component.html'
})
export class EachAppComponent {
	public baseUrl: string = environment.baseUrl;
  @Input() appData: any;

  linkClick(url:string){
    // Check if the URL starts with "http://" or "https://"
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      // If it doesn't start with either, add "http://" to the URL
      url = 'http://' + url;
    }
    // Open the URL in a new tab
    window.open(url, '_blank');
  }
}