<div class="article">
    <div class="right" *ngIf="articleData?.Image?.formats?.small?.url; else thumbnailImage">
        <img src="{{imageUrl+articleData.Image.formats.small.url}}" alt="Article Image">
    </div>
    <div class="left">
        <a *ngIf="articleData?.Title && articleData?.id" role="button" (click)="navigateTo('/blog/'+articleData.Slug)" class="text-decoration-none"><h3 class="sec-heading3">{{articleData.Title}}</h3></a>
        <div class="posted-by">
            <span *ngIf="articleData?.createdBy?.firstname">Posted by {{articleData?.createdBy?.firstname+' '+articleData?.createdBy?.lastname}}</span>
            <span class="dot">
                <svg viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.950195 1.99609V1.71582C0.950195 1.32389 1.07324 1.00033 1.31934 0.745117C1.56999 0.489909 1.90951 0.362305 2.33789 0.362305C2.77083 0.362305 3.11263 0.489909 3.36328 0.745117C3.61393 1.00033 3.73926 1.32389 3.73926 1.71582V1.99609C3.73926 2.38346 3.61393 2.70475 3.36328 2.95996C3.11719 3.21061 2.77767 3.33594 2.34473 3.33594C1.91634 3.33594 1.57682 3.21061 1.32617 2.95996C1.07552 2.70475 0.950195 2.38346 0.950195 1.99609Z" fill="#8392A8"/></svg>                    
            </span>
            <span *ngIf="articleData?.createdAt">{{ articleData.createdAt | transformTime}}</span>
        </div>
        <div *ngIf="articleData?.Content" class="content" [innerHTML]="articleData?.Content">
        </div>
        <div class="upvote-comment-share">
            <div class="upvote elem" [ngClass]="{'active' : isUpvoteTrue}" role="button" (click)="onUpvoteClick(articleData.id)">
                <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.1875 5.75L6.4375 0.25C6.59375 0.09375 6.78125 0.03125 7 0.03125C7.1875 0.03125 7.375 0.09375 7.53125 0.25L12.7812 5.75C13.0625 6.0625 13.0625 6.53125 12.75 6.8125C12.4688 7.09375 11.9688 7.09375 11.6875 6.78125L7.75 2.625V13.25C7.75 13.6875 7.40625 14 7 14C6.625 14 6.25 13.6875 6.25 13.25V2.625L2.28125 6.78125C2 7.09375 1.5 7.09375 1.21875 6.8125C0.90625 6.53125 0.90625 6.03125 1.1875 5.75Z" fill="#008ECF"/></svg>
                <span *ngIf="numberOfUpvotes">{{numberOfUpvotes}}</span>
            </div>
            <div class="comment elem" role="button" (click)="navigateTo('/blog/'+articleData.Slug+'?comments=true')">
                <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.9688 0C16.0938 0 16.9688 0.90625 16.9688 2V10.9688C16.9688 12.0312 16.0625 12.9375 14.9688 12.9375H10.4688L6.5625 15.8438C6.3125 16.0312 5.96875 15.875 5.96875 15.5625V12.9375H2.96875C1.84375 12.9375 0.96875 12.0625 0.96875 10.9688V2C0.96875 0.90625 1.84375 0 2.96875 0H14.9688ZM15.5 11V2C15.5 1.75 15.25 1.5 15 1.5H3C2.71875 1.5 2.5 1.75 2.5 2V11C2.5 11.2812 2.71875 11.5 3 11.5H7.5V13.375L10 11.5H15C15.25 11.5 15.5 11.2812 15.5 11Z" fill="#008ECF"/></svg>
                <span *ngIf="numberOfComments">{{numberOfComments}}</span>
            </div>
            <div class="share elem">
                <app-share-popup [calledFrom]="'other-page'" [blogUrl]="hostURL+'/blog/'+articleData.Slug"></app-share-popup>
            </div>
        </div>
    </div>
    <ng-template #thumbnailImage>
        <div class="right" *ngIf="articleData?.Image?.formats?.thumbnail?.url">
            <img src="{{imageUrl+articleData.Image.formats.thumbnail.url}}" alt="Article Image">
        </div>
    </ng-template>
</div>
