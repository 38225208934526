import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeImageText'
})
export class RemoveImageTextPipe implements PipeTransform {
  transform(value: string): string {
    const regex = /!\[[^\]]*\]\([^)]+\)/g;

    // Remove all image-related text
    const outputString = value.replace(regex, '');
    return outputString;
  }
}
