import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter  } from '@angular/core';

@Component({
  selector: 'app-comment-list',
  templateUrl: './comment-list.component.html'
})
export class CommentListComponent implements OnInit, OnChanges {
  @Input() commentsList: any;
  @Output() replyClickCL: EventEmitter<any> = new EventEmitter<any>();

  commentsListKeys:any;

  ngOnInit(): void {
    this.commentsListKeys=Object.keys(this.commentsList).reverse();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['commentsList'].currentValue) {
      this.commentsList=changes['commentsList'].currentValue;
      this.commentsListKeys=Object.keys(this.commentsList).reverse();
    }
  }

  onReplyClick(data: any) {
    this.replyClickCL.emit(data);
  }
}
