import { Component, OnInit, Inject, HostListener, ElementRef, Renderer2 } from '@angular/core';
import { AuthService, MainService } from '@services';
import { environment } from '@environments';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
	public data:any={};
	public imageUrl: string = environment.imageUrl;
	public shortUserName: string ='';
  
	//subscriptions
	  private headerDataSubscription!: Subscription;
	  private userDataSubscription!: Subscription;

	constructor(
		public mainService: MainService,
		public authService: AuthService,
        @Inject(DOCUMENT) private dom: any,
		private renderer: Renderer2,
		private el: ElementRef
	) {
	}

	ngOnInit(): void {
		this.headerDataSubscription=this.mainService.getHeaderData().subscribe(({data}:any)=>{
			this.data=data;
			
			const link = this.dom.createElement('link');
			link['type'] = 'image/x-icon';
			link['rel'] = 'icon';
			link['href'] = this.imageUrl+data.attributes.favicon.data.attributes.url;
			this.dom.getElementsByTagName('head')[0].appendChild(link)
		})
		
		this.userDataSubscription=this.authService.getUserData().subscribe((userData:any)=>{
			const regex = /\(([^)]+)\)/;
			if(Object.keys(userData).length != 0){
				const matches = userData.username.match(regex);
				if (matches && matches.length > 1) {
					this.shortUserName = matches[1];
				}
			}
		});
	}

	@HostListener('window:scroll', ['$event'])
	onWindowScroll(event: Event) {
		const scrollPosition = document.documentElement.scrollTop || document.body.scrollTop || 0;
		
		let header=this.el.nativeElement.querySelector('header');
		if (scrollPosition > 88) {
			this.renderer.setStyle(header, 'background-color', 'rgba(255, 255, 255)');
		}
		else{
			this.renderer.setStyle(header, 'background-color', 'rgba(255, 255, 255, 0.85)');
		}
	}

	ngOnDestroy() {
	  if (this.headerDataSubscription) {
		this.headerDataSubscription.unsubscribe();
	  }
	  if (this.userDataSubscription) {
		this.userDataSubscription.unsubscribe();
	  }
	}

}
