import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { AuthService } from '@services';
import { environment } from '@environments';
import { catchError, throwError, Subject } from 'rxjs';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest, EventMessage, EventType } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
declare const gtag: Function;
type ProfileType = {
	givenName?: string,
	surname?: string,
	displayName?: string,
	userPrincipalName?: string,
	id?: string
  };

@Component({	
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
	title = 'Stolt Tankers Business Technology & Innovation';
	isIframe = false;
	loginDisplay = false;
	private readonly _destroying$ = new Subject<void>();

	constructor(
		@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
		private broadcastService: MsalBroadcastService,
		private msalService: MsalService,
		private authService: AuthService,
		private http: HttpClient
	){
		this.addGAScript();
	}

	ngOnInit(){
		this.isIframe = window !== window.parent && !window.opener;

		this.broadcastService.inProgress$
		.pipe(
		  filter((status: InteractionStatus) => status === InteractionStatus.None),
		  takeUntil(this._destroying$)
		)
		.subscribe(() => {
		  this.setLoginDisplay();
		  this.getProfile();
		})
	}
	setLoginDisplay() {
		this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
	}
	getProfile() {
		this.http.get(environment.GRAPH_ENDPOINT)
		  .subscribe((profile:ProfileType) => {
			let userData:any={
				password:profile?.id,
				email:profile?.userPrincipalName,
				username:profile?.displayName,
			}
			this.authService.ssoUserDetails=userData;//store sso user in service variable
			this.login(userData);
		});
	}
	
	login(userData:any){
		let loginData={
			identifier: userData.email,
			password: userData.password
		}
		this.authService.loginStrapi(loginData)
		.pipe(
			catchError(error => {
			  // Handle the error here
			  console.error('An error occurred:', error);
			  if(error.status!=401 && error.status!=200){
				  this.addNewUser(userData)
			  }
			  return throwError(() => error);
			})
		)
		.subscribe((data:any)=>{
			if(data?.jwt){
				this.authService.setTokenObservable(data?.jwt);
			}
			if(Object.keys(data?.user)?.length > 0){
				this.authService.setUserData(data?.user);
                this.initGAUserByID(data);
			}
			else{
				this.addNewUser(userData)
			}
		});
	}
	
	addNewUser(userData:any){
		this.authService.addUserStrapi(userData).subscribe((data:any)=>{
			if(data?.jwt){
				this.authService.setTokenObservable(data?.jwt);
			}
			if(Object.keys(data?.user)?.length > 0){
				this.authService.setUserData(data?.user);
				this.initGAUserByID(data);
			}
			else{
			}
		});
	}

	/** Add Google Analytics Script Dynamically */
	addGAScript()
	{
		if(environment.googleAnalytic ==='uat' || environment.googleAnalytic ==='local' || environment.googleAnalytic ==='dev' || environment.googleAnalytic ==='prod'){

			(function(w:any,d:any,s:any,l:any,i:any){w[l]=w[l]||[];
				w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
				let f:any=d.getElementsByTagName(s)[0];
				let j:any=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
				j.async=true;
				j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
				})(window,document,'script','dataLayer',`${environment.googleTagManager}`);

			let gtagScript: HTMLScriptElement = document.createElement('script');
			gtagScript.async = true;
			gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.googleAnalyticKey;
			document.head.prepend(gtagScript);
		
			gtag('config', environment.googleAnalytic);
			
		
		
			
			let noscript = document.createElement('noscript')
			let iframe = document.createElement('iframe');
			iframe.setAttribute('src',`https://www.googletagmanager.com/ns.html?id=${environment.googleTagManager}`)
			iframe.setAttribute("height", "0")
			iframe.setAttribute("width", "0")
			noscript.appendChild(iframe)
			document.body.appendChild(noscript);
			}
	}

	initGAUserByID(data:any){
		let obj: HTMLScriptElement = document.createElement('script');
		obj.async = true;
		obj.defer = true;
		obj.id = 'data-layer';
		obj.type = 'text/javascript';
		obj.innerHTML="window.dataLayer = window.dataLayer || [];window.dataLayer.push({ 'event': 'login','UserID': '"+ data.user.id +"'})"
		obj.crossOrigin = 'anonymous';
		document.head.prepend(obj);
	}
	
	ngOnDestroy(): void {
		this._destroying$.next(undefined);
		this._destroying$.complete();
	}
}
