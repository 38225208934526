import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

	constructor(
		private http: HttpClient
	) { }
 
  getBogDetail(blogSlug:string){
    return this.http.get(`${environment.baseUrl}/blog/${blogSlug}`);
  }

  addComment(data:Object,blogId:number){
    return this.http.post(`${environment.baseUrl}/blog/${blogId}/comments`,data);
  }
  addCommentUpvote(data:Object,commentId:number){
    return this.http.post(`${environment.baseUrl}/comment/${commentId}/upvote`,data);
  }
  deleteCommentUpvote(userId:number,commentId:number){
    return this.http.post(`${environment.baseUrl}/blog/upvote/delete/${commentId}/user/${userId}`,{});
  }

  shareBlog(data:Object){
    return this.http.post(`${environment.baseUrl}/share-resource/email`,data);
  }
}
