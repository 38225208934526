import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

	constructor(
		private http: HttpClient
	) { }
 
	 // ======================= //
    // Fetch Dept Page
    // ======================= //
    getAppPageData(slug:string){
      return this.http.get(`${environment.baseUrl}/app/${slug}?populate=deep`);
    }
    
    subscribeProduct(data:Object){
      return this.http.post(`${environment.baseUrl}/product/addSubscription`,data);
    }
    unsubscribeProduct(data:Object){
      return this.http.post(`${environment.baseUrl}/product/removeSubscription`,data);
    }

}
