import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformTime'
})
export class TransformTimePipe implements PipeTransform {
 
  transform(value: string): string {
    let currentTime = new Date();
    let previousTime = new Date(value);
    const timeDiff = currentTime.getTime() - previousTime.getTime();
  
    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    // const months = Math.floor(days / 30);
    const months = this.getMonths(currentTime, previousTime);
    const years = Math.floor(days / 365);
    if (seconds < 60) {
      return " just now";
    } else if (minutes < 60) {
      return minutes + " minutes ago";
    } else if (hours < 24) {
      return hours + " hours ago";
    } else if (days < 7) {
      return days + " days ago";
    } else if (weeks < 4) {
      return weeks + " weeks ago";
    } else if (months < 12) {
      return (months || 1) + " months ago";
    } else {
      return years + " years ago";
    }
  }

  getMonths(currentTime:any, previousTime:any){
    let curTime= new Date(previousTime);
    let months:number = 0;
    const numOfRoughYears = currentTime.getFullYear() - previousTime.getFullYear();
    let numOfRoughMonths = (currentTime.getMonth() - previousTime.getMonth()) + (numOfRoughYears * 12);
    for(let a=1; a <= numOfRoughMonths; a++){
      let numberOfDaysInMonth = this.getDaysInMonthFromDate(curTime)
      curTime.setDate(curTime.getDate() + numberOfDaysInMonth);
      if(curTime <= currentTime){
        months++;
      }
    }
    return months;
  }

  getDaysInMonthFromDate(date:any) {
    const year = date.getFullYear();
    const month = date.getMonth();
    // Create a date object for the first day of the next month
    const nextMonth = new Date(year, month + 1, 1);

    // Subtract one day to get the last day of the current month
    nextMonth.setDate(nextMonth.getDate() - 1);

    // Return the last day of the current month
    return nextMonth.getDate();
  }

}
