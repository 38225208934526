import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter  } from '@angular/core';
import { BlogService, AuthService } from '@services';
import { Subscription, catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-comment-each',
  templateUrl: './comment-each.component.html'
})
export class CommentEachComponent implements OnInit {
  @Input() commentData: any;
  @Output() replyClick: EventEmitter<any> = new EventEmitter();

  constructor(
    private blogService: BlogService,
    private authService: AuthService
    ){}
  numberOfReplies!:any;
  numberOfUpvotes!:any;
  isUpvoteTrue:boolean=false;
  
  //subscriptions
	private userDataSubscription!: Subscription;
	private addCommentUpvoteSubscription!: Subscription;
	private deleteCommentUpvoteSubscription!: Subscription;

  ngOnInit(): void {
    this.numberOfReplies=this.commentData?.children ? Object.keys(this.commentData.children).length : '';
    this.numberOfUpvotes=this.commentData?.upvotes?.length;

    this.userDataSubscription=this.authService.getUserData().subscribe((userData:any)=>{
      if(this.commentData?.upvotes?.length>0)
      {
        this.isUpvoteTrue = this.commentData?.upvotes?.some((upvote: any) => 
        {
          return upvote?.user?.id === userData?.id
        });
      }
    })
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['commentData'].currentValue) {
      this.commentData=changes['commentData'].currentValue;
      this.numberOfReplies=this.commentData?.children ? Object.keys(this.commentData.children).length : '';
      this.numberOfUpvotes=this.commentData?.upvotes?.length;
    }
  }

  onReplyClick(commentData:any) {
    this.replyClick.emit(commentData);
  }
  onUpvoteClick(commentId:any) {
    if(this.isUpvoteTrue)
    {
      this.deleteUpvote(commentId);
    }
    else{
      this.addUpvote(commentId);
    }
  }
  addUpvote(commentId:any){
    let data={
      vote:true,
      user:this.authService.UserObj.id
    }
    this.isUpvoteTrue=true;
    this.numberOfUpvotes++;
    this.addCommentUpvoteSubscription=this.blogService.addCommentUpvote(data,commentId)
		.pipe(
			catchError(error => {
			  // Handle the error here
			  console.error('An error occurred:', error);
        this.isUpvoteTrue=false;
        this.numberOfUpvotes--;
			  return throwError(() => error);
			})
		).subscribe(()=>{
    })
  }
  deleteUpvote(commentId:any){
    this.isUpvoteTrue=false;
    this.numberOfUpvotes--;
    this.deleteCommentUpvoteSubscription=this.blogService.deleteCommentUpvote(this.authService.UserObj.id,commentId)
		.pipe(
			catchError(error => {
			  // Handle the error here
			  console.error('An error occurred:', error);
        this.isUpvoteTrue=true;
        this.numberOfUpvotes++;
			  return throwError(() => error);
			})
		).subscribe(()=>{
    })
  }

  ngOnDestroy() {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
    if (this.addCommentUpvoteSubscription) {
      this.addCommentUpvoteSubscription.unsubscribe();
    }
    if (this.deleteCommentUpvoteSubscription) {
      this.deleteCommentUpvoteSubscription.unsubscribe();
    }
  }
}
