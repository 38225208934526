import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  public currentBlogId!: number;
  
  private isInvokeGetBlogDetail=new BehaviorSubject(false);
  updateInvokeGetBlogDetails(value:any){
    this.isInvokeGetBlogDetail.next(value);
  }
  getInvokeGetBlogDetails(){
    return this.isInvokeGetBlogDetail;
  }
}
