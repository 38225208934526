import { Component, Input } from '@angular/core';
import { environment } from '@environments';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html'
})
export class PeopleComponent {
  @Input() peopleData: any;
	public baseUrl: string = environment.baseUrl;
	public imageUrl: string = environment.imageUrl;

}
