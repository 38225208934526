import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tree-part-vertical',
  templateUrl: './tree-part-vertical.component.html'
})
export class TreePartVerticalComponent {
  @Input() data: any;
  @Input() indexNumber: any;
  public randomNumber:number = Math.floor(Math.random() * 100);
}
