import { Component, Input } from '@angular/core';
import { ProductService, AuthService } from '@services';
import { Subscription, catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-top-section',
  templateUrl: './top-section.component.html'
})
export class TopSectionComponent {

  constructor(
    private productService:ProductService,
    private authService:AuthService
  ) {}
  
  @Input() topSection: any;
  @Input() productId: any;
  @Input() subscribers: any;
  public subStatus:boolean=false;
  public subUnsubClass:string='sub';
  public subUnsubText:string='Subscribe';
  
	//subscriptions
  private subscribeProductSubscription!: Subscription;
  private unsubscribeProductSubscription!: Subscription;

  ngOnInit() {
    //
      if(this.subscribers?.length>0)
      {
        this.subStatus = this.subscribers?.some((subscriber: any) => 
        {
          return subscriber?.user?.id === this.authService.UserObj.id
        });
        if(this.subStatus===true){
          this.subUnsubClass='unsub';
          this.subUnsubText='Unsubscribe';
        }
        else{
          this.subUnsubClass='sub';
          this.subUnsubText='Subscribe';
        }
      }
  }

  launchClick(url:string){
    // Check if the URL starts with "http://" or "https://"
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      // If it doesn't start with either, add "http://" to the URL
      url = 'http://' + url;
    }
    // Open the URL in a new tab
    window.open(url, '_blank');
  }
  
  subUnsub(){
    if(this.subStatus){
      this.subUnsubClass='sub';
      this.subUnsubText='Subscribe';
      this.unsubscribeProduct();
    }
    else{
      this.subUnsubClass='unsub';
      this.subUnsubText='Unsubscribe';
      this.subscribeProduct();
    }
    this.subStatus = !this.subStatus;
  }

  subscribeProduct(){
		let requestData={
			IsSubscribe: true,
			product: this.productId,
			user: this.authService.UserObj.id
		}
    this.subscribeProductSubscription=this.productService.subscribeProduct(requestData)
		.pipe(
			catchError(error => {
			  // Handle the error here
			  console.error('An error occurred:', error);
        this.subUnsubClass='sub';
        this.subUnsubText='Subscribe';
        this.subStatus = !this.subStatus;
			  return throwError(() => error);
			})
		).subscribe(()=>{
    })
  }

  unsubscribeProduct(){
		let requestData={
			productId: this.productId,
			userId: this.authService.UserObj.id
		}
    this.unsubscribeProductSubscription=this.productService.unsubscribeProduct(requestData)
		.pipe(
			catchError(error => {
			  // Handle the error here
			  console.error('An error occurred:', error);
        this.subUnsubClass='unsub';
        this.subUnsubText='Unsubscribe';
        this.subStatus = !this.subStatus;
			  return throwError(() => error);
			})
		).subscribe(()=>{
    })
  }

	ngOnDestroy() {
	  if (this.subscribeProductSubscription) {
      this.subscribeProductSubscription.unsubscribe();
	  }
	  if (this.unsubscribeProductSubscription) {
      this.unsubscribeProductSubscription.unsubscribe();
	  }
	}
}
