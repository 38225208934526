import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-roadmap',
  templateUrl: './roadmap.component.html'
})
export class RoadmapComponent {
  @Input() roadmapData: any;
  @Input() index: any;
}
