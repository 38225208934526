import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
	constructor(
		private http: HttpClient
	) { }

  //Set and get Logged in User Details in variable and BehaviorSubject
  public UserObj:any={};
  private UserSubject=new BehaviorSubject({});
  setUserData(value:any){
    this.UserObj=value;
    this.UserSubject.next(value);
  }
  getUserData(){
    return this.UserSubject;
  }
  
  //Set and get token in variable and BehaviorSubject
  public token:string='';
  private tokenSubject = new BehaviorSubject<string | null>(null);
  setTokenObservable(value:string){
    this.token=value;
    this.tokenSubject.next(value);
  }
  getTokenObservable(): BehaviorSubject<string | null> {
    return this.tokenSubject;
  }
  
 //Fetch SSO User Detail API
  getLoggedInUserDetail(){
    return this.http.get(`${environment.hostURL}/.auth/me`);
  }
  public ssoUserDetails:any={};

  //Strapi API's
  addUserStrapi(data:{}){
    return this.http.post(`${environment.baseUrl}/signup`,data);
  }
  loginStrapi(data:{}){
    return this.http.post(`${environment.baseUrl}/login`,data);
  }

  //used in error interceptor
  isRefreshed:boolean=false; //to track call of login api for token refresh in error interceptor.
}
